import React from "react";
import { NavLink } from "react-router-dom";
import Headerbar from "../headerbar/Headerbar";
import { FaWhatsappSquare } from "react-icons/fa";

const Navbar = () => {
  return (
    <>
      <div className="social" style={{ cursor: "pointer" }}>
        <a
          className="text-white"
          onClick={() => window.open("https://wa.me/8180830095", "_blank")}
        >
          <span className="fw-bold fs-6"> Chat Now</span> <FaWhatsappSquare />
        </a>
      </div>
      <Headerbar />
      <nav className="navbar navbar-expand-lg navbar-white shadow-sm bg-white sticky-top">
        <div className="container">
          <NavLink className="navbar-brand  text-white" to="/">
            <img src="/assets/logo.png" width={70} alt="" />
            <br />
          </NavLink>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link text-dark active"
                  aria-current="page"
                  to="/"
                >
                  HOME
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link  text-dark active"
                  aria-current="page"
                  to="/about"
                >
                  ABOUT US
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link  text-dark active"
                  aria-current="page"
                  to="/services"
                >
                  OUR SERVICES
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link  text-dark active"
                  aria-current="page"
                  to="/contact"
                >
                  CONTACT US
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
