import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-dark text-light py-5">
      {/* Newsletter Section */}
      <Container className="mb-4">
        <Row className="align-items-center">
          <Col md={4}>
            <div className="d-flex align-items-center">
              <h5 className="mb-0 me-3">NEED HELP?</h5>
              <span>CALL US:</span>
              <a
                style={{ cursor: "pointer" }}
                // href="tel:+919130318485"
                className="text-white ms-2 text-decoration-none"
                onClick={() =>
                  window.open("https://wa.me/8180830095", "_blank")
                }
              >
                8180830095
              </a>
            </div>
          </Col>
          {/* <Col md={8}>
            <Form className="d-flex gap-2">
              <Form.Control
                type="email"
                placeholder="Please enter your email..."
                className="flex-grow-1"
              />
              <Button variant="secondary">SIGN UP</Button>
            </Form>
          </Col> */}
        </Row>
      </Container>

      {/* Main Footer Content */}
      <Container>
        <Row className="gy-4">
          {/* Products Column */}
          <Col md={3}>
            <h5 className="mb-3">Our Services</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <NavLink
                  to="/services"
                  className="text-light text-decoration-none"
                >
                  Auto Mini Strip
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink
                  to="/services"
                  className="text-light text-decoration-none"
                >
                  Auto Sticker Marketing
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/" className="text-light text-decoration-none">
                  Auto Hood Marketing
                </NavLink>
              </li>
            </ul>
          </Col>

          {/* Services Column */}
          {/* <Col md={3}>
            <h5 className="mb-3">Our Services</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <NavLink to="/" className="text-light text-decoration-none">
                  Wide Reach Marketing
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/" className="text-light text-decoration-none">
                  Targeted Local Marketing
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/" className="text-light text-decoration-none">
                  Custom Design Services
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/" className="text-light text-decoration-none">
                  Brand Recognition Solutions
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/" className="text-light text-decoration-none">
                  Pan India Services
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/" className="text-light text-decoration-none">
                  Demographics Targeting
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/" className="text-light text-decoration-none">
                  Brand Visibility Solutions
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/" className="text-light text-decoration-none">
                  Custom Auto Advertising
                </NavLink>
              </li>
            </ul>
          </Col> */}

          {/* Contact Column */}
          <Col md={4}>
            <h5 className="mb-3">Contact Us</h5>
            <address className="text-light">
              <strong>AUTO BRANDING PAN INDIA</strong>
              <br />
              PAN INDIA SERVICE
              <br />
              <br />
              <strong>Mobile:</strong>{" "}
              <a
                onClick={() =>
                  window.open("https://wa.me/8180830095", "_blank")
                }
                className="text-light text-decoration-none"
              >
                8180830095
              </a>
              <br />
              <strong>Email:</strong>{" "}
              <a
                href="mailto:Autobrandingpanindia@gmail.com"
                className="text-light text-decoration-none"
              >
                autobrandingpanindia@gmail.com
              </a>
            </address>
          </Col>

          {/* Social Media Column */}
          <Col md={2}>
            <h5 className="mb-3">Follow Us</h5>
            <div className="d-flex gap-3">
              <a
                href="https://www.facebook.com/share/12G3edSNFiU/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white fs-4"
              >
                <FaFacebook />
              </a>
              <a
                href="https://www.youtube.com/@autobranding-01"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white fs-4"
              >
                <FaYoutube />
              </a>
              <a
                href="https://www.instagram.com/auto.branding/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white fs-4"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.linkedin.com/in/auto-branding-79a5132a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white fs-4"
              >
                <FaLinkedinIn />
              </a>
              <a
                style={{ cursor: "pointer" }}
                // href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white fs-4"
                onClick={() =>
                  window.open("https://wa.me/8180830095", "_blank")
                }
              >
                <FaWhatsapp />
              </a>
            </div>
          </Col>
        </Row>

        {/* Copyright */}
        <Row className="mt-4">
          <Col className="text-center border-top pt-3">
            <small>
              Copyright © 2025 Auto Branding Pan India - All rights reserved.
            </small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
