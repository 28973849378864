import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log("Form submitted:", formData);
  };

  return (
    <div className="min-vh-100 py-5">
      <Container>
        <div className="text-center mb-5">
          <h2 className="text-center fw-bold mb-2">Contact Us</h2>
          <p className="text-center text-muted mb-5">
            Get in touch with us for any questions or concerns.{" "}
          </p>
        </div>

        <Row className="g-4">
          {/* Contact Information Cards */}
          <Col lg={4}>
            <div className="d-flex flex-column gap-4">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <i className="bi bi-telephone-fill text-primary fs-4 me-3"></i>
                    <div>
                      <h3 className="h5 mb-1">Phone</h3>
                      <p className="text-muted mb-0">+91 8180830095</p>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <i className="bi bi-envelope-fill text-primary fs-4 me-3"></i>
                    <div>
                      <h3 className="h5 mb-1">Email</h3>
                      <p className="text-muted mb-0">
                        autobrandingpanindia@gmail.com
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <i className="bi bi-geo-alt-fill text-primary fs-4 me-3"></i>
                    <div>
                      <h3 className="h5 mb-1">Address</h3>
                      <p className="text-muted mb-0">
                        Gangapur Road, Nashik, Maharashtra 422005
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>

          {/* Contact Form */}
          <Col lg={8}>
            <Card>
              <Card.Header className="bg-white">
                <h2 className="h4 mb-0">Send us a Message</h2>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-3" controlId="subject">
                    <Form.Label>Contact</Form.Label>
                    <Form.Control
                      type="number"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    onClick={() =>
                      window.open("https://wa.me/8180830095", "_blank")
                    }
                    className="w-100 d-flex align-items-center justify-content-center gap-2"
                  >
                    <i className="bi bi-send-fill"></i>
                    <span>Send Message</span>
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactPage;
