import React, { useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
// Import Swiper styles
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Home = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const playMutedVideo = async () => {
      if (videoRef.current) {
        try {
          videoRef.current.muted = false; // Ensure autoplay works
          await videoRef.current.play(); // Start muted autoplay
          console.log("Muted autoplay started");
        } catch (error) {
          console.error("Muted autoplay failed:", error);
        }
      }
    };

    const enableSound = () => {
      if (videoRef.current) {
        videoRef.current.muted = false;
        videoRef.current.volume = 1.0;
        videoRef.current.play();
        console.log("Sound enabled after user interaction");

        document.removeEventListener("click", enableSound);
        document.removeEventListener("touchstart", enableSound);
      }
    };

    // Try muted autoplay first
    playMutedVideo();

    // Attach event listeners to enable sound on user interaction
    document.addEventListener("click", enableSound);
    document.addEventListener("touchstart", enableSound);

    return () => {
      document.removeEventListener("click", enableSound);
      document.removeEventListener("touchstart", enableSound);
    };
  }, []);

  const clients = [
    {
      id: 1,
      img: "/client/a (1).jpeg",
    },
    {
      id: 2,
      img: "/client/a (2).jpeg",
    },
    {
      id: 3,
      img: "/client/a (3).jpeg",
    },
    {
      id: 4,
      img: "/client/a (4).jpeg",
    },
    {
      id: 5,
      img: "/client/a (5).jpeg",
    },
    {
      id: 6,
      img: "/client/a (6).jpeg",
    },
    {
      id: 7,
      img: "/client/a (7).jpeg",
    },
    {
      id: 8,
      img: "/client/a (8).jpeg",
    },
    {
      id: 9,
      img: "/client/a (9).jpeg",
    },
    {
      id: 10,
      img: "/client/a (10).jpeg",
    },
    {
      id: 11,
      img: "/client/a (11).jpeg",
    },
    {
      id: 12,
      img: "/client/a (12).jpeg",
    },
    {
      id: 13,
      img: "/client/a (13).jpeg",
    },
    {
      id: 14,
      img: "/client/a (14).jpeg",
    },
    {
      id: 15,
      img: "/client/a (15).jpeg",
    },
    {
      id: 16,
      img: "/client/a (16).jpeg",
    },
    {
      id: 17,
      img: "/client/a (17).jpeg",
    },
    {
      id: 18,
      img: "/client/a (18).jpeg",
    },
    {
      id: 19,
      img: "/client/a (19).jpeg",
    },
    {
      id: 20,
      img: "/client/a (20).jpeg",
    },
    {
      id: 21,
      img: "/client/a (21).jpeg",
    },
    {
      id: 22,
      img: "/client/a (22).jpeg",
    },
  ];

  const testimonial = [
    {
      id: 1,
      quote:
        "The services provided were absolutely top-notch! The banners were movable, cost-effective, and grabbed attention instantly. Highly impressed with the quality and durability of the materials used!",
      company: "Pebbles",
      person: "Marketing Director",
    },
    {
      id: 2,
      quote:
        "Affordable prices combined with high-quality material make this the best branding service I’ve come across. The eye-level marketing approach ensured a wide reach for my business.",
      company: "Wavin ",
      person: "Brand Manager",
    },
    {
      id: 3,
      quote:
        "Their designs are excellent and well thought out! The durable banners are perfect for long-term campaigns, and the pricing fits any budget.",
      company: "Ayurva healthcare",
      person: "CEO",
    },
    {
      id: 4,
      quote:
        "I was blown away by their movable banners. They are practical, attractive, and helped me connect with a wider audience effectively. Absolutely cost-effective!",
      company: "Conqueror academy",
      person: "CEO",
    },
    {
      id: 5,
      quote:
        "Eye-level marketing is a game-changer! My campaigns gained so much visibility thanks to their creative and durable materials. Worth every penny!",
      company: "Neev construction ",
      person: "CEO",
    },
    {
      id: 6,
      quote:
        "Amazing quality at such affordable prices! The banners were not only visually appealing but also lasted longer than I expected. Highly recommend it!",
      company: "Raj computers",
      person: "CEO",
    },
    {
      id: 6,
      quote:
        "From the design process to delivery, everything was seamless. The banners stood out due to their vibrant colors and high-quality finish. A great investment for marketing!",
      company: "Sanskar english medium school",
      person: "CEO",
    },
    {
      id: 7,
      quote:
        "I loved their attention to detail! The movable banners were easy to handle, and the affordability made it the best decision for my marketing budget.",
      company: "Pooja developers  ",
      person: "CEO",
    },
    {
      id: 8,
      quote:
        "They offer the perfect combination of quality and affordability. The wide reach achieved through their marketing strategies was beyond my expectations!",
      company: "Surya electronics",
      person: "CEO",
    },
    {
      id: 9,
      quote:
        "Their materials are incredibly durable and built to last. My banners still look brand new after months of use, and their services were worth every cent!",
      company: "Eatoz",
      person: "CEO",
    },
    {
      id: 10,
      quote:
        "The wide reach of their marketing solutions helped me grow my business significantly. Their pricing is so competitive for the quality they deliver!",
      company: "Jay developers",
      person: "CEO",
    },
    {
      id: 11,
      quote:
        "Best branding service out there! Their eye-level marketing strategy has helped me target the right audience, and the results were phenomenal.",
      company: "Bodke buildcon",
      person: "CEO",
    },
    {
      id: 12,
      quote:
        "The banners were designed to perfection, and the cost-effectiveness of their solutions made them stand out from competitors. Highly satisfied!",
      company: "Samarth infra",
      person: "CEO",
    },
    {
      id: 12,
      quote:
        "Durability, affordability, and aesthetics—all rolled into one. I will definitely be returning for more branding solutions soon!",
      company: "Kishor alankar",
      person: "CEO",
    },
    {
      id: 13,
      quote:
        "Their movable banners are a brilliant concept! I can use them for multiple events, and they still maintain their excellent quality.",
      company: "Rise and shine academy",
      person: "CEO",
    },
    {
      id: 14,
      quote:
        "The materials used were of premium quality, and the banners were crafted with precision. A perfect solution for cost-effective advertising!",
      company: "Space classes",
      person: "CEO",
    },

    {
      id: 15,
      quote:
        "I can’t recommend them enough! Eye-level marketing and high-quality banners made my campaign an instant success.",
      company: "Bk coaching classes",
      person: "CEO",
    },
    {
      id: 16,
      quote:
        "Their wide reach strategies helped me gain more visibility than ever before. The banners are both affordable and built to last!",
      company: "Powerconep solar",
      person: "CEO",
    },
    {
      id: 17,
      quote:
        "An absolute game-changer for my brand! The high-quality materials and durable banners made my marketing campaigns so effective.",
      company: "Capsule construction ",
      person: "CEO",
    },
    {
      id: 18,
      quote:
        "Top-notch services with great pricing. Their banners are movable and attract attention wherever I place them. Excellent durability too!",
      company: "Mobile expertise",
      person: "CEO",
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      {/* <section
        className="text-white py-5"
        style={{ backgroundColor: "#0A97B0" }}
      >
        <Container>
          <Row className="align-items-center">
            <Col lg={6} className="mb-4 mb-lg-0">
              <h1 className="display-4 fw-bold mb-4">
                Transform Your Brand with Auto Rickshaw Advertising
              </h1>
              <p className="fw-bold mb-4">
                ENGAGE THOUSANDS OF PEOPLE DAILY TO SEE YOUR ADVERTISE ON
                ROAD..!
              </p>
              <Button
                onClick={() =>
                  window.open("https://wa.me/8180830095", "_blank")
                }
                variant="light"
                size="lg"
                className="fw-bold"
              >
                Get Started
              </Button>
            </Col>
            <Col lg={6}>
              <img
                src="/assets/auto.png"
                alt="Auto Rickshaw Branding"
                className="img-fluid rounded shadow"
              />
            </Col>
          </Row>
        </Container>
      </section> */}

      <div style={{ width: "100%", height: "auto", overflow: "hidden" }}>
        <video
          ref={videoRef}
          src="/1711182631152_1711182638128.mp4"
          type="video/mp4"
          loop
          playsInline
          autoPlay
          controls
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
          }}
        ></video>
      </div>
      {/* About Section */}
      <section className="py-5">
        <Container>
          <Row className="justify-content-center text-center mb-5">
            <Col lg={8}>
              <h2 className="fw-bold mb-4">INTRODUCTION</h2>
              <p className="fs-4 fw-bold">
                Auto branding is an innovative and cost-effective outdoor
                advertising strategy. Since its inception in 2016. Our business
                has been committed to transforming auto-rickshaws into mobile
                billboards that maximize brand visibility. This dynamic form of
                advertising leverages the mobility and wide spread presence of
                auto-rickshaws to ensure extensive reach in urban and semi-urban
                areas.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Benefits Section */}
      <section className="bg-light py-5">
        <Container>
          <h2 className="text-center fw-bold mb-5">
            Why Choose Auto Branding?
          </h2>
          <Row className="g-4">
            {[
              {
                title: "Wide Reach",
                icon: "bi-globe",
                description:
                  "Autos travel through various areas, exposing your brand to a diverse audience, including urban, suburban, and rural areas.",
              },
              {
                title: "Cost-Effective",
                icon: "bi-piggy-bank",
                description:
                  "It's an affordable advertising option compared to traditional media like billboards or digital ads.",
              },
              {
                title: "High Visibility",
                icon: "bi-eye",
                description:
                  "Ads are displayed prominently at eye level, making them hard to miss in traffic or crowded streets.",
              },
              {
                title: "Targeted Marketing",
                icon: "bi-geo-alt",
                description:
                  "Ideal for businesses targeting specific neighborhoods or regions.",
              },
              {
                title: "Brand Recall",
                icon: "bi-arrow-repeat",
                description:
                  "Repeated exposure in different areas helps in building strong brand recognition and recall.",
              },
              {
                title: "Customizable Design",
                icon: "bi-palette",
                description:
                  "Creative and visually appealing designs can be used to capture attention and make a lasting impression.",
              },
            ].map((benefit, index) => (
              <Col md={6} lg={4} key={index}>
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body className="text-center p-4">
                    <i
                      className={`bi ${benefit.icon} text-primary fs-1 mb-3`}
                    ></i>
                    <h4 className="fw-bold mb-3">{benefit.title}</h4>
                    <p className="text-muted mb-0">{benefit.description}</p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* New Happy Clients Section */}
      <section className="py-5 bg-light">
        <Container>
          <h2 className="text-center fw-bold mb-2">Our Happy Clients</h2>
          <p className="text-center text-muted mb-5">
            Trusted by leading brands across India
          </p>

          <div className="container-xl mt-3 p-3">
            {/* <div className="row">
              <div className="col-md-10 ">
                <h4 className="text-uppercase">Our Happy Clients</h4>
              </div>
              <div className="col-md-2 text-center">
                <button className="btn btn-dark p-2 my-2">
                  <NavLink
                    to="/merchants"
                    className="text-white text-decoration-none"
                  >
                    VIEW ALL <BsFillArrowRightCircleFill />
                  </NavLink>
                </button>
              </div>
            </div> */}
            <Swiper
              // autoplay={{
              //   delay: 2000, // 3 seconds delay
              //   disableOnInteraction: false,
              // }}
              // modules={[Navigation, A11y, Autoplay, Pagination]}
              // spaceBetween={10}
              // pagination={{
              //   clickable: true,
              //   dynamicBullets: true,
              // }}
              // slidesPerView={3}
              // navigation
              // breakpoints={{
              //   640: {
              //     slidesPerView: 2,
              //     spaceBetween: 10,
              //   },
              //   768: {
              //     slidesPerView: 3,
              //     spaceBetween: 10,
              //   },
              //   1024: {
              //     slidesPerView: 4,
              //     spaceBetween: 10,
              //   },
              // }}

              autoplay={{
                delay: 2000, // 3 seconds delay
                disableOnInteraction: false,
              }}
              modules={[Navigation, A11y, Autoplay, Pagination]}
              spaceBetween={10}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              slidesPerView={1}
              navigation
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
            >
              <div className=" row">
                {/* admin */}
                {clients.map((item) => {
                  return (
                    <>
                      <SwiperSlide key={item.id}>
                        <div className="col card bg-light shadow-sm text-dark ">
                          <img
                            src={item.img}
                            alt=""
                            height={300}
                            // className="card-img-top zoom-effect "
                          />
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </div>
            </Swiper>
          </div>

          <div className="container-xl mt-5 p-3">
            <h2 className="text-center fw-bold mb-5">Our Testimonials</h2>

            <Swiper
              autoplay={{
                delay: 2000, // 3 seconds delay
                disableOnInteraction: false,
              }}
              modules={[Navigation, A11y, Autoplay, Pagination]}
              spaceBetween={10}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              slidesPerView={1}
              navigation
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
            >
              <div className=" row">
                {/* admin */}
                {testimonial.map((item, index) => {
                  return (
                    <>
                      <SwiperSlide key={item.id}>
                        <div
                          // className="col card bg-light shadow-sm text-dark "
                          key={index}
                          style={{ height: "430px" }}
                        >
                          <Card className="h-100 border-0 shadow-sm">
                            <Card.Body className="p-5 ms-3">
                              <p className="mb-4">{item.quote}</p>
                              <div className="align-items-center">
                                {/* <div
                                  className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center"
                                  style={{ width: "48px", height: "48px" }}
                                >
                                  <i className="bi bi-person-fill fs-4"></i>
                                </div> */}
                                <div className="3">
                                  <h5 className="mb-1">{item.company}</h5>
                                  <p className="text-muted mb-0">
                                    {/* {item.person} */}
                                  </p>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </div>
            </Swiper>
          </div>

          {/* Client Testimonials */}
          {/* <Row className="mt-5 g-4">
            {[
              {
                quote:
                  "The auto rickshaw branding campaign significantly increased our local visibility and customer engagement.",
                company: "Company Name 1",
                person: "Marketing Director",
              },
              {
                quote:
                  "Perfect solution for our hyperlocal marketing needs. The team's execution was flawless.",
                company: "Company Name 2",
                person: "Brand Manager",
              },
              {
                quote:
                  "Cost-effective and highly impactful. We saw immediate results in target neighborhoods.",
                company: "Company Name 3",
                person: "CEO",
              },
            ].map((testimonial, index) => (
              <Col md={4} key={index}>
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body className="p-4">
                    <i className="bi bi-quote text-primary fs-1 mb-3"></i>
                    <p className="mb-4">{testimonial.quote}</p>
                    <div className="d-flex align-items-center">
                      <div
                        className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center"
                        style={{ width: "48px", height: "48px" }}
                      >
                        <i className="bi bi-person-fill fs-4"></i>
                      </div>
                      <div className="ms-3">
                        <h5 className="mb-1">{testimonial.company}</h5>
                        <p className="text-muted mb-0">{testimonial.person}</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row> */}

          {/* Trust Indicators */}
          <Row className="mt-5 text-center">
            <Col md={4}>
              <div className="mb-4 mb-md-0">
                <h3 className="fw-bold text-primary">500+</h3>
                <p className="mb-0 fw-bold fs-4">Campaigns Completed</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4 mb-md-0">
                <h3 className="fw-bold text-primary">100+</h3>
                <p className="mb-0 fw-bold  fs-4">Cities Covered</p>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <h3 className="fw-bold text-primary">1,00,000+</h3>
                <p className="mb-0 fw-bold fs-4">Auto Rickshaws Network</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Services Section */}
      <section className="py-5">
        <Container>
          <h2 className="text-center fw-bold mb-5">Our Services</h2>
          <Row className="g-4">
            {[
              {
                title: "AUTO HOOD MARKETING",
                specs: 'Size - 40" × 17" | 20" × 17" | 28" × 7"',
                img: "/assets/s (2).jpeg",
                material: "Material - Rexine Hood",
                price: "₹. 750/-",
                includes:
                  "Printing, Cutting, stiching, Transport, Installation, Data sheet, Photo file, Auto",
              },
              {
                title: "AUTO STICKER MARKETING",
                specs: "Size - 2ft × 2ft",
                material: "Material - Vinyl Print",
                img: "/assets/s (1).jpeg",
                price: "₹. 250/-",
                includes:
                  "Printing, Cutting, Pasting, Transport, Photo file, Auto",
              },
              {
                title: "AUTO MINI STRIP",
                specs: "Size - 27 inch × 7 inch",
                material: "Material - Vinyl Print",
                price: "₹. 150/-",
                img: "/assets/s (3).jpeg",
                includes:
                  "Printing, Cutting, Pasting, Transport, Photo file, Auto",
              },
            ].map((service, index) => (
              <Col md={4} key={index}>
                <Card className="h-100 text-center bg-white border-0 shadow-sm">
                  <Card.Body className="p-4">
                    <img src={service.img} className="img-fluid" alt="" />
                    <h5 className="fw-bold text-dark mb-3 mt-4">
                      {service.title}
                    </h5>
                    <p className="mb-2 text-dark">{service.specs}</p>
                    <p className="mb-2 text-dark">{service.material}</p>
                    {/* <p
                      className="fw-bold fs-4  mb-3"
                      style={{ color: "#0A97B0" }}
                    >
                      {service.price}
                    </p> */}
                    <p className="text-dark small mb-0">
                      Includes: {service.includes}
                    </p>
                    <Button
                      onClick={() =>
                        window.open("https://wa.me/8180830095", "_blank")
                      }
                      variant="primary"
                      size="sm"
                      className="fw-bold mt-3"
                    >
                      Contact Now
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* CTA Section */}
      {/* <section className="bg-primary text-white py-5">
        <Container className="text-center">
          <h2 className="fw-bold mb-4">
            Ready to Boost Your Brand Visibility?
          </h2>
          <p className="lead mb-4">Contact us today for PAN India services!</p>
          <div className="d-flex justify-content-center gap-3">
            <Button variant="light" className="fw-bold">
              <i className="bi bi-telephone-fill me-2"></i>
              9130318485
            </Button>
            <Button variant="outline-light" className="fw-bold">
              <i className="bi bi-envelope-fill me-2"></i>
              Autobrandingpanindia@gmail.com
            </Button>
          </div>
        </Container>
      </section> */}
    </div>
  );
};

export default Home;
