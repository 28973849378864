import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaPhoneAlt,
  FaEnvelope,
  FaWhatsapp,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import "./headerbar.css";

const Headerbar = () => {
  return (
    <div className="p-2 bg-dark">
      <div className="row">
        <div className="col-md-6">
          <span
            style={{ cursor: "pointer" }}
            className="text-white"
            onClick={() => window.open("https://wa.me/8180830095", "_blank")}
          >
            <FaPhoneAlt className="text-white" /> +91 8180830095
          </span>
          <br />
          <span>
            <FaEnvelope className="text-white" />{" "}
            <a
              href="mailto:autobrandingpanindia@gmail.com"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              className="text-white"
            >
              autobrandingpanindia@gmail.com{" "}
            </a>
          </span>
        </div>
        <div className="col-md-6  text-end social-icons ">
          <a
            href="https://www.facebook.com/share/12G3edSNFiU/"
            target="_blank"
            className="text-white fs-5"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.youtube.com/@autobranding-01"
            target="_blank"
            className="text-white fs-5"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
          <a
            href="https://www.instagram.com/auto.branding/"
            target="_blank"
            className="text-white fs-5"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/in/auto-branding-79a5132a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            target="_blank"
            className="text-white fs-5"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
          <a
            style={{ cursor: "pointer" }}
            // href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white fs-5"
            onClick={() => window.open("https://wa.me/8180830095", "_blank")}
          >
            <FaWhatsapp />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Headerbar;
