import React from "react";

const About = () => {
  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
      }}
    >
      <h2 className="text-center fw-bold mb-5">About Us</h2>

      <div className="row">
        <div className="col-md-6">
          <p style={{ fontSize: "18px" }}>
            Welcome to <strong>Auto Branding</strong>, where innovation meets
            visibility.
          </p>
          <p style={{ fontSize: "18px", textAlign: "justify" }}>
            At <strong>Auto Branding</strong>, we are passionate about
            transforming businesses into recognizable brands. With a mission to
            deliver high-quality and cost-effective branding solutions, we
            specialize in creating visually striking, durable, and versatile
            marketing materials tailored to meet your specific needs.
          </p>
          <p style={{ fontSize: "18px", textAlign: "justify" }}>
            Our team of experts understands the importance of impactful
            advertising and ensures that every project we handle is designed to
            maximize your brand's reach. Whether it's{" "}
            <strong>movable banners</strong> for versatile promotions,
            <strong>eye-level marketing</strong> to engage your audience
            effectively, or using <strong>high-quality materials</strong> for
            long-lasting impressions, we deliver nothing but the best.
          </p>
          <p style={{ fontSize: "18px", textAlign: "justify" }}>
            At Auto Branding, we don’t just create banners; we craft
            opportunities for your business to shine. Let us help you leave a
            lasting impression in the minds of your audience.{" "}
          </p>
        </div>

        <div className="col-md-6 mb-4">
          <img
            src="/assets/auto.png" // Replace this with your image URL
            alt="Auto Advertising Banner"
            className="self-align-start"
            style={{
              width: "100%",
              maxWidth: "800px",
              height: "auto",
              borderRadius: "10px",
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          />{" "}
        </div>
      </div>

      <div className="container row">
        <div className="col">
          <h2 style={{ marginTop: "30px", color: "#2E3B55" }}>Vision</h2>
          <p style={{ textAlign: "justify" }}>
            To be the leading auto marketing powerhouse, revolutionizing how
            automotive businesses build their brand, connect with customers, and
            drive growth through innovative strategies.
          </p>
        </div>
        <div className="col">
          <h2 style={{ marginTop: "30px", color: "#2E3B55" }}> Mission</h2>
          <p style={{ textAlign: "justify" }}>
            Our mission is to help businesses effectively promote their products
            and services to a diverse audience while staying budget-friendly.
            With vibrant designs, strategic placements, and customized
            solutions, we make sure your brand stands out in the bustling
            streets. Whether you’re a startup or an established brand, we
            provide a powerful platform to amplify your message
          </p>
        </div>
        <div className="col">
          <h2 style={{ marginTop: "30px", color: "#2E3B55" }}>
            Why Choose Us?
          </h2>
          <ul
            style={{
              fontSize: "18px",
              marginLeft: "20px",
              textAlign: "justify",
            }}
          >
            <li>
              <strong>Best Services:</strong> Seamless and client-focused
              experience from start to finish.
            </li>
            <li>
              <strong>Affordable Pricing:</strong> Premium quality at
              budget-friendly rates.
            </li>
            <li>
              <strong>Wide Reach:</strong> Strategies that help you connect with
              a larger audience effortlessly.
            </li>{" "}
            <li>
              <strong>Durable Materials:</strong> Designed to withstand the test
              of time, ensuring maximum value.
            </li>
            <li>
              <strong>Innovative Solutions:</strong> Movable banners and
              creative designs that stand out.
            </li>
          </ul>
        </div>
      </div>

      <div style={{ textAlign: "center", marginTop: "40px" }}>
        <button
          style={{
            backgroundColor: "#2E3B55",
            color: "#fff",
            padding: "10px 20px",
            fontSize: "18px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => window.open("https://wa.me/8180830095", "_blank")}
        >
          Contact Us Today!
        </button>
      </div>
    </div>
  );
};

export default About;
