import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

const Services = () => {
  return (
    <div>
      {" "}
      {/* Services Section */}
      {/* Services Section */}
      <section className="py-5">
        <Container>
          <h2 className="text-center fw-bold mb-5">Our Services</h2>
          <Row className="g-4">
            {[
              {
                title: "AUTO HOOD MARKETING",
                specs: 'Size - 40" × 17" | 20" × 17" | 28" × 7"',
                img: "/assets/s (2).jpeg",
                material: "Material - Rexine Hood",
                price: "₹. 750/-",
                includes:
                  "Printing, Cutting, stiching, Transport, Installation, Data sheet, Photo file, Auto",
              },
              {
                title: "AUTO STICKER MARKETING",
                specs: "Size - 2ft × 2ft",
                material: "Material - Vinyl Print",
                img: "/assets/s (1).jpeg",
                price: "₹. 250/-",
                includes:
                  "Printing, Cutting, Pasting, Transport, Photo file, Auto",
              },

              {
                title: "AUTO MINI STRIP",
                specs: "Size - 27 inch × 7 inch",
                material: "Material - Vinyl Print",
                price: "₹. 150/-",
                img: "/assets/s (3).jpeg",
                includes:
                  "Printing, Cutting, Pasting, Transport, Photo file, Auto",
              },
            ].map((service, index) => (
              <Col md={4} key={index}>
                <Card className="h-100 text-center bg-white border-0 shadow-sm">
                  <Card.Body className="p-4">
                    <img src={service.img} className="img-fluid" alt="" />
                    <h5 className="fw-bold text-dark mb-3 mt-4">
                      {service.title}
                    </h5>
                    <p className="mb-2 text-dark">{service.specs}</p>
                    <p className="mb-2 text-dark">{service.material}</p>
                    {/* <p
                         className="fw-bold fs-4  mb-3"
                         style={{ color: "#0A97B0" }}
                       >
                         {service.price}
                       </p> */}
                    <p className="text-dark small mb-0">
                      Includes: {service.includes}
                    </p>
                    <Button
                      onClick={() =>
                        window.open("https://wa.me/8180830095", "_blank")
                      }
                      variant="primary"
                      size="sm"
                      className="fw-bold mt-3"
                    >
                      Contact Now
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Services;
