import "./App.css";
import Navbar from "./components/navbar/Navbar";
import About from "./Pages/about/About";
import ContactPage from "./Pages/contact/Contact";
import Home from "./Pages/home/Home";
import { Routes, Route } from "react-router-dom";
import Services from "./Pages/services/Services";
import ScrollToTop from "./ScrollToTop";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
